<template>
  <div class="p-4">
    <h1 class="text-4xl text-center uppercase">Dremica Lending</h1>
    <div v-if="valid">
          <div class="flex flex-col">
            <SelectButton unstyled v-model="type" :allow-empty="false" optionLabel="name" optionValue="value" :options="[
              {
                name: 'Offers',
                value: 'offers'
              },
              {
                name: 'Requests',
                value: 'requests'
              }
            ]" :pt="{
                  root: {
                    class: 'bg-white flex p-1 rounded-md inline-flex items-center gap-2 my-4'
                  },
                  pcToggleButton: {
                    root: ({ context }) => ({
                      class: [
                        'rounded-md px-3 py-1 uppercase flex-1',
                        {
                          'bg-primary text-white hover:bg-secondary': context.active,
                          'bg-white text-primary hover:text-secondary': !context.active
                        }
                      ]
                    })
                  }
                }" />

            <Button unstyled :pt="{
              root: {
                class: 'ml-auto bg-primary border border-white rounded-md self-center px-2 py-1 uppercase hover:bg-secondary'
              }
            }
              " :label="'Add ' + (type == 'offers' ? 'offer' : 'request')" @click="createVisible = true" />

            <Dialog unstyled v-model:visible="createVisible" :draggable="false" modal
              :header="'Add ' + (type == 'offers' ? 'offer' : 'request')" :pt="{
                root: ({ state }) => ({
                  class: [
                    // Shape
                    'rounded-lg',
                    'shadow-lg',
                    'border-0',
                    'text-primary',

                    // Size
                    'max-h-[90vh]',
                    'm-0',

                    // Color
                    'bg-white',

                    // Transitions
                    'transform',
                    'scale-100',

                    // Maximized State
                    {
                      'transition-none': state.maximized,
                      'transform-none': state.maximized,
                      '!w-screen': state.maximized,
                      '!h-screen': state.maximized,
                      '!max-h-full': state.maximized,
                      '!top-0': state.maximized,
                      '!left-0': state.maximized
                    }
                  ]
                }),
                title: {
                  class: ['font-semibold text-xl leading-[normal]']
                },
                headerActions: {
                  class: ['flex items-center']
                },
                header: {
                  class: [
                    // Flexbox and Alignment
                    'flex items-center justify-between',
                    'shrink-0',

                    // Spacing
                    'p-6',
                  ]
                },
                content: () => ({
                  class: [
                    // Spacing
                    'px-6',
                    'pb-6',
                    'pt-0',

                    'overflow-y-auto'
                  ]
                }),
                footer: {
                  class: [
                    // Flexbox and Alignment
                    'flex items-center justify-end',
                    'shrink-0',
                    'text-right',
                    'gap-2',

                    // Spacing
                    'px-6',
                    'pb-6',
                  ]
                },
                mask: ({ props }) => ({
                  class: [
                    // Transitions
                    'transition-all',
                    'duration-300',
                    { 'p-5': !props.position == 'full' },

                    // Background and Effects
                    { 'has-[.mask-active]:bg-transparent bg-black/40': props.modal }
                  ]
                }),
                transition: ({ props }) => {
                  return props.position === 'top'
                    ? {
                      enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
                      enterActiveClass: 'transition-all duration-200 ease-out',
                      leaveActiveClass: 'transition-all duration-200 ease-out',
                      leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active'
                    }
                    : props.position === 'bottom'
                      ? {
                        enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active'
                      }
                      : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
                        ? {
                          enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
                          enterActiveClass: 'transition-all duration-200 ease-out',
                          leaveActiveClass: 'transition-all duration-200 ease-out',
                          leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active'
                        }
                        : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
                          ? {
                            enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active'
                          }
                          : {
                            enterFromClass: 'opacity-0 scale-75 mask-active',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass: 'opacity-0 scale-75 mask-active'
                          };
                }
              }">
              <div class="flex flex-col gap-4 mb-8">
                <div class="flex flex-col gap-1">
                  <div>
                    Your username
                  </div>
                  <div>
                    <InputText type="text" unstyled :invalid="username === null || username?.trim() == ''" :pt="{
                      root: ({ props }) => ({
                        class: [
                          'border-primary border px-2 py-1 rounded-md w-full focus:outline-none bg-white text-primary',
                          {
                            '!border-red-700': props.invalid
                          }
                        ]
                      })
                    }" v-model="username" />
                  </div>
                </div>
                <div class="flex flex-col gap-1" v-if="type == 'requests'">
                  <div>
                    Your player code
                  </div>
                  <div>
                    <InputText type="text" unstyled :invalid="playerCode === null || playerCode?.trim() == ''" :pt="{
                      root: ({ props }) => ({
                        class: [
                          'border-primary border px-2 py-1 rounded-md w-full focus:outline-none bg-white text-primary',
                          {
                            '!border-red-700': props.invalid
                          }
                        ]
                      })
                    }" v-model="playerCode" />
                  </div>
                </div>
                <div class="flex flex-col gap-1" v-if="type == 'offers'">
                  <div>
                    Amount of NFTs to lend
                  </div>
                  <div>
                    <InputText type="text" unstyled :invalid="amount === null || amount <= 0" :pt="{
                      root: ({ props }) => ({
                        class: [
                          'border-primary border px-2 py-1 rounded-md w-full focus:outline-none bg-white text-primary',
                          {
                            '!border-red-700': props.invalid
                          }
                        ]
                      })
                    }" v-model.number="amount" />
                  </div>
                </div>
                <div class="flex flex-col gap-1" v-if="type == 'offers'">
                  <div>
                    Fungible Token Rewards Share
                  </div>
                  <div class="mb-4">

                    <InputGroup :pt="{
                      root: {
                        class: ['flex items-stretch', 'w-full']
                      }
                    }">
                      <InputText type="text"
                        :invalid="lendingPercentage === null || lendingPercentage < 0 || lendingPercentage > 100"
                        unstyled :pt="{
                          root: ({ props }) => ({
                            class: [
                              'border-primary border px-2 py-1 rounded-l-md border-r-0 w-full focus:outline-none bg-white text-primary',
                              {
                                '!border-red-700': props.invalid
                              }
                            ]
                          })
                        }" v-model.number="lendingPercentage" />

                      <InputGroupAddon unstyled :pt="{
                        root: {
                          class: 'border-primary border px-2 py-1 rounded-r-md rounded-l-0 focus:outline-none bg-white text-primary'
                        }
                      }">%</InputGroupAddon>
                    </InputGroup>
                  </div>
                  <div>
                    <Slider unstyled :pt="{
                      root: {
                        class: 'bg-gray-100 h-[3px] rounded-md relative'
                      },
                      range: {
                        class: 'bg-primary h-[3px]'
                      },
                      handle: {
                        class: 'bg-primary rounded-full w-4 h-4 -translate-y-1/2 -translate-x-1/2'
                      }
                    }" v-model="lendingPercentage" class="w-full" />
                    <div class="mt-4">
                      {{ lendingPercentage }}% to you, {{ 100 - lendingPercentage }}% to lendee
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-1" v-if="type == 'offers'">
                  <div>
                    Non-Fungible Token Rewards
                  </div>
                  <div>
                    <SelectButton unstyled v-model="nftCatcher" :allow-empty="false" optionLabel="name"
                      optionValue="value" :options="[
                        {
                          name: 'You',
                          value: 0
                        },
                        {
                          name: 'Lendee',
                          value: 1
                        }
                      ]" :pt="{
                  root: {
                    class: 'bg-white flex p-1 rounded-md flex items-center gap-2 border border-primary'
                  },
                  pcToggleButton: {
                    root: ({ context }) => ({
                      class: [
                        'rounded-md px-3 py-1 uppercase flex-1',
                        {
                          'bg-primary text-white hover:bg-secondary': context.active,
                          'bg-white text-primary hover:text-secondary': !context.active
                        }
                      ]
                    })
                  }
                }" />
                  </div>
                </div>
              </div>
              <div class="flex justify-end gap-2">
                <Button unstyled type="button" :pt="{
                  root: {
                    class: 'ml-auto bg-white border border-primary rounded-md self-center px-2 py-1  min-w-[7rem] uppercase text-primary hover:text-secondary'
                  }
                }
                  " label="Cancel" severity="secondary" @click="createVisible = false"></Button>
                <Button unstyled type="button" :pt="{
                  root: {
                    class: 'ml-auto bg-primary border border-white text-white rounded-md text-center min-w-[7rem] self-center px-2 py-1 uppercase hover:bg-secondary'
                  }
                }
                  " label="Add" @click.prevent="save()"></Button>
              </div>
            </Dialog>

            <Dialog unstyled v-model:visible="acceptVisible" :draggable="false" modal
              :header="'Accept ' + (acceptData?.type == 'offers' ? 'offer' : 'request')" :pt="{
                root: ({ state }) => ({
                  class: [
                    // Shape
                    'rounded-lg',
                    'shadow-lg',
                    'border-0',
                    'text-primary',

                    // Size
                    'max-h-[90vh]',
                    'm-0',

                    // Color
                    'bg-white',

                    // Transitions
                    'transform',
                    'scale-100',

                    // Maximized State
                    {
                      'transition-none': state.maximized,
                      'transform-none': state.maximized,
                      '!w-screen': state.maximized,
                      '!h-screen': state.maximized,
                      '!max-h-full': state.maximized,
                      '!top-0': state.maximized,
                      '!left-0': state.maximized
                    }
                  ]
                }),
                title: {
                  class: ['font-semibold text-xl leading-[normal]']
                },
                headerActions: {
                  class: ['flex items-center']
                },
                header: {
                  class: [
                    // Flexbox and Alignment
                    'flex items-center justify-between',
                    'shrink-0',

                    // Spacing
                    'p-6',
                  ]
                },
                content: () => ({
                  class: [
                    // Spacing
                    'px-6',
                    'pb-6',
                    'pt-0',

                    'overflow-y-auto'
                  ]
                }),
                footer: {
                  class: [
                    // Flexbox and Alignment
                    'flex items-center justify-end',
                    'shrink-0',
                    'text-right',
                    'gap-2',

                    // Spacing
                    'px-6',
                    'pb-6',
                  ]
                },
                mask: ({ props }) => ({
                  class: [
                    // Transitions
                    'transition-all',
                    'duration-300',
                    { 'p-5': !props.position == 'full' },

                    // Background and Effects
                    { 'has-[.mask-active]:bg-transparent bg-black/40': props.modal }
                  ]
                }),
                transition: ({ props }) => {
                  return props.position === 'top'
                    ? {
                      enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
                      enterActiveClass: 'transition-all duration-200 ease-out',
                      leaveActiveClass: 'transition-all duration-200 ease-out',
                      leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active'
                    }
                    : props.position === 'bottom'
                      ? {
                        enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active'
                      }
                      : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
                        ? {
                          enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
                          enterActiveClass: 'transition-all duration-200 ease-out',
                          leaveActiveClass: 'transition-all duration-200 ease-out',
                          leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active'
                        }
                        : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
                          ? {
                            enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active'
                          }
                          : {
                            enterFromClass: 'opacity-0 scale-75 mask-active',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass: 'opacity-0 scale-75 mask-active'
                          };
                }
              }">
              <p class="mb-4" v-if="acceptData?.type == 'offers'">Do you want to send a request to {{
                acceptData.username }}?<br />Your percentage would be: {{ 100 - acceptData.lendingPercentage
                }}%<br />You will get <span v-html="!acceptData.nftCatcher ? '<strong>no</strong>' : ''"></span> extra
                NFT rewards</p>
              <p class="mb-4" v-else>Do you want to send a request to {{ acceptData.username }} ?</p>
              <div class="flex flex-col gap-4 mb-8">
                <div class="flex flex-col gap-1">
                  <div>
                    Your username
                  </div>
                  <div>
                    <InputText type="text" unstyled :invalid="username === null || username?.trim() == ''" :pt="{
                      root: ({ props }) => ({
                        class: [
                          'border-primary border px-2 py-1 rounded-md w-full focus:outline-none bg-white text-primary',
                          {
                            '!border-red-700': props.invalid
                          }
                        ]
                      })
                    }" v-model="username" />
                  </div>
                </div>
                <div class="flex flex-col gap-1" v-if="acceptData?.type == 'offers'">
                  <div>
                    Your player code
                  </div>
                  <div>
                    <InputText type="text" unstyled :invalid="playerCode === null || playerCode?.trim() == ''" :pt="{
                      root: ({ props }) => ({
                        class: [
                          'border-primary border px-2 py-1 rounded-md w-full focus:outline-none bg-white text-primary',
                          {
                            '!border-red-700': props.invalid
                          }
                        ]
                      })
                    }" v-model="playerCode" />
                  </div>
                </div>
              </div>
              <div class="flex justify-end gap-2">
                <Button unstyled type="button" :pt="{
                  root: {
                    class: 'ml-auto bg-white border border-primary rounded-md self-center px-2 py-1  min-w-[7rem] uppercase text-primary hover:text-secondary'
                  }
                }
                  " label="Cancel" severity="secondary" @click="acceptData = null"></Button>
                <Button v-if="acceptData.userId == this.telegramUserId" unstyled type="button" :pt="{
                  root: {
                    class: 'ml-auto bg-primary border border-white text-white rounded-md text-center min-w-[7rem] self-center px-2 py-1 uppercase hover:bg-secondary'
                  }
                }
                  " label="Delete your entry" @click="deleteEntry()"></Button>
                  <Button unstyled type="button" :pt="{
                  root: {
                    class: 'ml-auto bg-primary border border-white text-white rounded-md text-center min-w-[7rem] self-center px-2 py-1 uppercase hover:bg-secondary'
                  }
                }
                  " label="Send accept request" @click="accept()" v-else></Button>
              </div>
            </Dialog>
          </div>
          
          <div v-if="data[type].length > 0">
          <div class="flex flex-col gap-4 my-4">
              <div class="border border-white rounded-md p-2 bg-secondary flex flex-col gap-4" v-for="entry of data[type]" :key="entry">
                <div class="font-semibold text-lg">{{ entry.username }}</div>
                <div v-if="type == 'offers'">
                  <div><strong>Rewards share: {{ 100 - entry.lendingPercentage }}%</strong> for you, <span v-html="!entry.nftCatcher ? '<strong>No</strong>' : ''"></span> extra NFT rewards</div>
                  <div>{{ entry.amount }} to lend</div>
                </div>

                <Button unstyled type="button" :pt="{
              root: {
                class: 'bg-white border-primary text-primary rounded-md text-center w-full text-center self-center px-2 py-1 uppercase hover:text-secondary'
              }
            }
              " label="Details" @click.prevent="acceptData = { ...entry, type }"></Button>
              </div>
              </div>
         </div>
         <p v-else class="text-center my-4">No {{ type }} yet</p>
    </div>
    <div class="text-center" v-else>Invalid data!</div>
  </div>
</template>

<script>
import '@/assets/style.css'
import axios from 'axios'
import SelectButton from "primevue/selectbutton"
import Button from "primevue/button"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import Slider from "primevue/slider"
import InputGroup from "primevue/inputgroup"
import InputGroupAddon from "primevue/inputgroupaddon"

export default {
  name: 'App',
  components: {
    SelectButton,
    Button,
    Dialog,
    InputText,
    Slider,
    InputGroup,
    InputGroupAddon
  },

  mounted() {
    this.loadData()
  },
  computed: {
    telegramUserId() {
      return window.Telegram.WebApp.initDataUnsafe.user.id
    },
    acceptVisible: {
      get() {
        return this.acceptData !== null
      },
      set() {
        this.acceptData = null
      }
    }
  },
  methods: {
    loadData() {
      axios
        .post(`/api/data`, { userdata: window.Telegram.WebApp.initData },
          {
            withCredentials: true,
          })
        .then((response) => {
          this.data = response.data.data
          setTimeout(() => this.loadData(), 10000)
        })
        .catch(() => {
          this.valid = false
        })
    },
    save() {
      let payload = {}

      if (this.type == 'requests') {

        if (this.username === null || this.username.trim() == '') {
          return
        }
        if (this.playerCode === null || this.playerCode.trim() == '') {
          return
        }
        payload = {
          type: 'request',
          playercode: this.playerCode,
          username: this.username,
          userid: window.Telegram.WebApp.initDataUnsafe.user.id,
          userdata: window.Telegram.WebApp.initData
        }
      } else {

        if (this.amount === null || this.amount <= 0) {
          return
        }

        if (this.username === null || this.username.trim() == '') {
          return
        }
        if (this.lendingPercentage === null || this.lendingPercentage < 0 || this.lendingPercentage > 100) {
          return
        }
        payload = {
          type: 'offer',
          username: this.username,
          userid: window.Telegram.WebApp.initDataUnsafe.user.id,
          lendingpercentage: this.lendingPercentage,
          nftcatcher: this.nftCatcher,
          amount: this.amount,
          userdata: window.Telegram.WebApp.initData
        }
      }

      axios
        .post(`/api/new`, payload,
          {
            withCredentials: true,
          })
        .then(() => {
          this.loadData()
          this.createVisible = false
        })
        .catch(() => {
          this.valid = false
        })
    },
    accept() {
      let payload = {}
      if (this.acceptData.type == 'offers') {
        payload = {
          playercode: this.playerCode,
          username: this.username,
          accept: this.acceptData,
          userid: window.Telegram.WebApp.initDataUnsafe.user.id,
          userdata: window.Telegram.WebApp.initData
        }
      } else {
        payload = {
          username: this.username,
          accept: this.acceptData,
          userid: window.Telegram.WebApp.initDataUnsafe.user.id,
          userdata: window.Telegram.WebApp.initData
        }
      }

      axios
        .post(`/api/accept`, payload,
          {
            withCredentials: true,
          })
        .then(() => {
          this.loadData()
          this.acceptData = null
        })
        .catch(() => {
          this.valid = false
        })
    },
    deleteEntry() {
      let payload = {}
      if (this.acceptData.type == 'offers') {
        payload = {
          accept: this.acceptData,
          userid: window.Telegram.WebApp.initDataUnsafe.user.id,
          userdata: window.Telegram.WebApp.initData
        }
      } else {
        payload = {
          accept: this.acceptData,
          userid: window.Telegram.WebApp.initDataUnsafe.user.id,
          userdata: window.Telegram.WebApp.initData
        }
      }

      axios
        .post(`/api/delete`, payload,
          {
            withCredentials: true,
          })
        .then(() => {
          this.loadData()
          this.acceptData = null
        })
        .catch(() => {
          this.valid = false
        })
    }
  },
  data: () => ({
    valid: true,
    acceptData: null,
    username: window.Telegram?.WebApp?.initDataUnsafe?.user?.username ?? null,
    lendingPercentage: 50,
    nftCatcher: 0,
    amount: 1,
    createVisible: false,
    playerCode: null,
    type: 'offers',
    columns: {
      offers: [
        {
          field: 'username',
          header: 'User'
        },
        {
          field: '_id',
          header: ''
        }
      ],
      requests: [
        {
          field: 'username',
          header: 'User'
        },
        {
          field: '_id',
          header: ''
        }
      ]
    },
    headers: {
      offers: [
        {
          title: 'Offerer',
          key: 'username'
        },
        {
          title: 'Percentage',
          key: 'lendingPercentage'
        },
        {
          title: '',
          key: 'actions',
          value: () => null,
          sortable: false
        }
      ],
      requests: [
        {
          title: 'Requester',
          key: 'username'
        },
        {
          title: '',
          key: 'actions',
          value: () => null,
          sortable: false
        }
      ]
    },
    data: {
      offers: [],
      requests: []
    }
  }),
}
</script>
