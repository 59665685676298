import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import { loadFonts } from './plugins/webfontloader'

loadFonts()

createApp(App).use(PrimeVue, {
  unstyled: true
})
  .mount('#app')
